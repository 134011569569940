


const FeedBackAlert = ({close, text}) => {
    setTimeout(() => {
        close(false)

    }, 3000)
    return (
        <div className="alert">
            
        <div className={`alert__content `}>
     {/*    <button onClick={() => close(false)} className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none px-4">
        <span>×</span>
    </button> */}
    <div className=" border-0 rounded relative ">
    <span className="inline-block align-middle text-center">
       {text}
    </span>
        
</div>
</div>
</div>
    )
}

export default FeedBackAlert;